export default function queAccordion() {
  const trigger = document.querySelectorAll(
    '[data-trigger-que-accordion]'
  ) as NodeListOf<Element>
  const target = document.querySelectorAll('[data-target-que-accordion]') as any

  if (!target.length) return

  for (var i = 0; i < trigger.length; i++) {
    let accordionFlg = false
    const height = window.matchMedia('(min-width: 930px)').matches
      ? target[i].clientHeight + 20
      : target[i].clientHeight + 15

    target[i].style.height = '0px'

    trigger[i].addEventListener('click', function () {
      if (!accordionFlg) {
        this.classList.add('active')
        this.nextElementSibling.style.height = `${height}px`
        accordionFlg = true
      } else {
        this.classList.remove('active')
        this.nextElementSibling.style.height = '0px'
        accordionFlg = false
      }
    })
  }
}
