export default function validate() {

  if (!document.querySelector('.contact-page')) {
    return;
  }

  // トリガー
  const triggerCompany = document.querySelector('[data-triggerCompany]');
  const triggerName = document.querySelector('[data-triggerName]');
  const triggerNameKana = document.querySelector('[data-triggerNameKana]');
  const triggerTel = document.querySelector('[data-triggerTel]');
  const triggerMail = document.querySelector('[data-triggerMail]');
  const triggerContent = document.querySelector('[data-triggerContent]');
  const triggerPrivacy = document.querySelector('[data-triggerPrivacy]');
  const triggerConfirm = document.querySelector('[data-triggerConfirm]');

  // メッセージ
  const validateMsgCompany = document.querySelector('[data-validateMsgCompany]');
  const validateMsgName = document.querySelector('[data-validateMsgName]');
  const validateMsgNameKana = document.querySelector('[data-validateMsgNameKana]');
  const validateMsgTel = document.querySelector('[data-validateMsgTel]');
  const validateMsgMail = document.querySelector('[data-validateMsgMail]');
  const validateMsgContent = document.querySelector('[data-validateMsgContent]');
  const validateMsgPrivacy = document.querySelector('[data-validateMsgPrivacy]');

  // 法人・組織名 検証
  // const companyVerification = () => {
  //   if (triggerCompany.value === "") {
  //     return false
  //   } else {
  //     return true
  //   };
  // };
  // 氏名 検証
  const nameVerification = () => {
    if (triggerName.value === "") {
      return false
    } else {
      return true
    };
  };
  // 氏名(フリガナ) 検証
  const nameKanaVerification = () => {
    if (triggerNameKana.value === "") {
      return false
    } else {
      return true
    };
  };
  // 電話番号 検証
  const telVerification = () => {
    const telCheck = /^[0-9]{10,11}$/.test(triggerTel.value);
    if (triggerTel.value === "") {
      return false
    } else if (!telCheck) {
      return false
    } else {
      return true
    };
  };
  // メールアドレス 検証
  const mailVerification = () => {
    const mailCheck = triggerMail.value.match(/.+@.+\..+/)
    if (triggerMail.value === "") {
      return false;
    } else if (!mailCheck) {
      return false;
    } else {
      return true;
    };
  };
  // お問い合わせ内容 検証
  const contentVerification = () => {
    if (triggerContent.value === "") {
      return false
    } else {
      return true
    };
  };
  // プライバシー 検証
  const privacyVerification = () => {
    if (triggerPrivacy.checked == false) {
      return false
    } else {
      return true
    };
  };

  // 法人・組織名 エラーメッセージ
  // const companyVerificationMsg = () => {
  //   if (companyVerification()) {
  //     validateMsgCompany.classList.remove("active");
  //   } else {
  //     validateMsgCompany.classList.add("active");
  //   };
  // };
  // 氏名 エラーメッセージ
  const nameVerificationMsg = () => {
    if (nameVerification()) {
      validateMsgName.classList.remove("active");
    } else {
      validateMsgName.classList.add("active");
    };
  };
  // 氏名(カナ) エラーメッセージ
  const nameKanaVerificationMsg = () => {
    if (nameKanaVerification()) {
      validateMsgNameKana.classList.remove("active");
    } else {
      validateMsgNameKana.classList.add("active");
    };
  };
  // 電話番号  エラーメッセージ
  const telVerificationMsg = () => {
    const telCheck = /^[0-9]{10,11}$/.test(triggerTel.value);
    if (triggerTel.value === "") {
      validateMsgTel.firstElementChild.textContent = "※電話番号を入力してください。";
      validateMsgTel.classList.add("active");
    } else if (!telCheck) {
      validateMsgTel.firstElementChild.textContent = "※正しい形式の電話番号を入力してください。";
      validateMsgTel.classList.add("active");
    } else {
      validateMsgTel.classList.remove("active");
    };
    return validateMsgTel.classList.contains("active");
  };
  // メールアドレス エラーメッセージ
  const mailVerificationMsg = () => {
    const mailCheck = triggerMail.value.match(/.+@.+\..+/)
    if (triggerMail.value === "") {
      validateMsgMail.firstElementChild.textContent = "※メールアドレスを入力してください。";
      validateMsgMail.classList.add("active");
    } else if (!mailCheck) {
      validateMsgMail.firstElementChild.textContent = "※正しい形式のメールアドレスを入力してください。";
      validateMsgMail.classList.add("active");
    } else {
      validateMsgMail.classList.remove("active");
    };
    return validateMsgMail.classList.contains("active");
  };
  // お問い合わせ内容 エラーメッセージ
  const contentVerificationMsg = () => {
    if (contentVerification()) {
      validateMsgContent.classList.remove("active");
    } else {
      validateMsgContent.classList.add("active");
    };
  };
  // プライバシー エラーメッセージ
  const privacyVerificationMsg = () => {
    if (triggerPrivacy.checked == false) {
      validateMsgPrivacy.classList.add("active");
    } else {
      validateMsgPrivacy.classList.remove("active");
    };
  };

  // 会社名からフォーカスが外れた時に発火
  // triggerCompany.addEventListener("blur", () => {
  //   companyVerificationMsg();
  // });
  // 氏名からフォーカスが外れた時に発火
  triggerName.addEventListener("blur", () => {
    nameVerificationMsg();
  });
  // 氏名(フリガナ)からフォーカスが外れた時に発火
  triggerNameKana.addEventListener("blur", () => {
    nameKanaVerificationMsg();
  });
  // 電話番号からフォーカスが外れた時に発火
  triggerTel.addEventListener("blur", () => {
    telVerificationMsg();
  });
  // メールアドレスからフォーカスが外れた時に発火
  triggerMail.addEventListener("blur", () => {
    mailVerificationMsg();
  });
  // お問い合わせ内容からフォーカスが外れた時に発火
  triggerContent.addEventListener("blur", () => {
    contentVerificationMsg();
  });

  // 確認画面 検証
  const confirmVerification = () => {
    // companyVerificationMsg();
    nameVerificationMsg();
    nameKanaVerificationMsg();
    telVerificationMsg();
    mailVerificationMsg();
    contentVerificationMsg();
    privacyVerificationMsg();
    return validateMsgCompany.classList.contains("active") ||
      validateMsgName.classList.contains("active") ||
      validateMsgNameKana.classList.contains("active") ||
      validateMsgTel.classList.contains("active") ||
      validateMsgMail.classList.contains("active") ||
      validateMsgContent.classList.contains("active") ||
      validateMsgPrivacy.classList.contains("active")
  }

  //  確認ボタン 押下時に発火
  if (triggerConfirm) {
    triggerConfirm.addEventListener("click", e => {
      //リンクが飛ばないようにする
      e.preventDefault();

      if (!confirmVerification()) {

        //  フォームデータ取得 lp-form validate
        const sendData = new FormData();
        sendData.append('key', 'S8PMa9Db3Edm3PE3TGywMqRhfaG6YmkMexh');
        const formData = {};
        const submitType = 'contacts';

        let validateApiUrl;
        validateApiUrl = '/api/' + submitType + '/validate';
        // validateApiUrl = '/wiz_avatar/api/' + submitType + '/validate';

        // validate api処理
        const fetchValidateApi = async sendData => {
          const validateResData = await fetchSendData(validateApiUrl, sendData);
          if (validateResData.data.code === 422) {
            // console.log('error')
          }
          if (validateResData.data.code === 200) {
            // console.log('validate ok')

            // 確認ページに遷移
            const formSection = document.querySelector('.contact-page__formWrap');
            const confirmSection = document.querySelector('.contact-page__confirmWrap');
            formSection.classList.remove("active");
            confirmSection.classList.add("active");
            window.scrollTo(0, 0);
            // 会社名を反映
            let dataCompany = triggerCompany.value;
            const confirmCompany = document.querySelector('[data-confirmCompany]');
            confirmCompany.textContent = dataCompany;
            // 氏名を反映
            let dataName = triggerName.value;
            const confirmName = document.querySelector('[data-confirmName]');
            confirmName.textContent = dataName;
            // 氏名(フリガナ)を反映
            let dataNameKana = triggerNameKana.value;
            const confirmNameKana = document.querySelector('[data-confirmNameKana]');
            confirmNameKana.textContent = dataNameKana;
            // 電話番号を反映
            let dataTel = triggerTel.value;
            const confirmTel = document.querySelector('[data-confirmTel]');
            confirmTel.textContent = dataTel;
            // メールアドレスを反映
            let dataMail = triggerMail.value;
            const confirmMail = document.querySelector('[data-confirmMail]');
            confirmMail.textContent = dataMail;
            // お問い合わせ内容を反映
            let dataContent = triggerContent.value;
            const confirmContent = document.querySelector('[data-confirmContent]');
            confirmContent.textContent = dataContent;
          }
        };

        const inputs = document.querySelectorAll('input'); //inputタグ
        for (let i = 0; i < inputs.length; i++) {
          sendData.append([inputs[i].name], inputs[i].value);
          formData[inputs[i].name] = inputs[i].value;
        }
        const textareas = document.querySelectorAll('textarea');//textareaタグ
        for (let i = 0; i < textareas.length; i++) {
          sendData.append([textareas[i].name], textareas[i].value);
        };
        fetchValidateApi(sendData);
      }
    });
  }

  // 入力画面に戻る
  const triggerBack = document.querySelector('[data-triggerBack]');
  if (triggerBack) {
    triggerBack.addEventListener("click", e => {
      //リンクが飛ばないようにする
      e.preventDefault();
      const formSection = document.querySelector('.contact-page__formWrap');
      const confirmSection = document.querySelector('.contact-page__confirmWrap');
      formSection.classList.add("active");
      confirmSection.classList.remove("active");
    });
  }

  // 送信してサンクスページに進む
  const triggerSend = document.querySelector('[data-triggerSend]');
  if (triggerSend) {
    triggerSend.addEventListener('click', e => {
      const submitType = 'contacts';
      let sendApiUrl;
      sendApiUrl = '/api/' + submitType + '/send';
      // sendApiUrl = '/wiz_avatar/api/' + submitType + '/send';

      //リンクが飛ばないようにする
      e.preventDefault();
      //フォームデータ取得
      const sendData = new FormData();
      sendData.append('key', 'S8PMa9Db3Edm3PE3TGywMqRhfaG6YmkMexh');

      //inputタグ
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i++) {
        sendData.append([inputs[i].name], inputs[i].value);
      }
      //textareaタグ
      const textareas = document.querySelectorAll('textarea');
      for (let i = 0; i < textareas.length; i++) {
        sendData.append([textareas[i].name], textareas[i].value);
      };

      const fetchSendApi = async sendData => {
        const sendResData = await fetchSendData(sendApiUrl, sendData);
        if (sendResData.data.code === 200) {
          location.href = `/contact/thanks`;
          // console.log('send ok')
        } else {
          alert('送信に失敗しました');
        }
      };
      fetchSendApi(sendData);
    });
  }


  // 送信系
  const fetchSendFunc = (url, sendData) =>
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: sendData,
    }).catch(() =>
      // console.log('ネットワークエラー')
      // ここにくる時はネットワークエラー（400系や500系のエラーはcatchされない）
      alert(
        'ネットワークエラーです。インターネットに接続されていることを確認して再度送信ください。'
      )
    );
  const fetchSendData = async (url, sendData) => {
    const fetch = await fetchSendFunc(url, sendData);
    const data = await fetch.json();
    return { data };
  };
};
