export default function scroll() {
  const targetElement = document.querySelector('[data-scrollTarget]'); // activeクラスを付与するエレメントを指定
  const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset; // エレメントの位置を取得

  function scrollHandler() {
    if (window.pageYOffset > targetPosition) {
      targetElement.classList.add('active');
    } else {
      targetElement.classList.remove('active');
    }
    // スクロールが一番上に戻った場合もactiveクラスを削除
    if (window.pageYOffset === 0) {
      targetElement.classList.remove('active');
    }
  }

  window.addEventListener('scroll', function () {
    requestAnimationFrame(scrollHandler);
  });
};