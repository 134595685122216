'use strict';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import MicroModal from 'micromodal';
import banner from './modules/banner';
import modal, { contactModal } from './modules/modal';
// import { scrollEvt } from './modules/scroll';
import tab from './modules/tab';
// import { lpForm } from './modules/lp-form';
import validate from './modules/validate';
import queAccordion from './modules/accordion';

import scroll from './modules/scroll';

// modal();
// contactModal();
window.onload = () => {
  // banner();
  // scrollEvt();
  scroll();
  // lpForm();
};

queAccordion();
validate();
